import React, { Component } from "react";
import { Link } from "react-router-dom";
import codeNamesJSON from "./codeNames.json";
import "./list.css";

import bayern from "./statePictures/Location_Bayern.svg";
import berlin from "./statePictures/Location_Berlin.svg";
import brandenburg from "./statePictures/Location_Brandenburg.svg";
import bremen from "./statePictures/Location_Bremen.svg";
import hamburg from "./statePictures/Location_Hamburg.svg";
import hessen from "./statePictures/Location_Hessen.svg";
import mecklenburg_vorpommern from "./statePictures/Location_Mecklenburg-Vorpommern.svg";
import niedersachsen from "./statePictures/Location_Niedersachsen.svg";
import nordrhein_westfalen from "./statePictures/Location_Nordrhein-Westfalen.svg";
import saarland from "./statePictures/Location_Saarland.svg";
import rheinland_pfalz from "./statePictures/Location_Rheinland-Pfalz.svg";
import sachsen_anhalt from "./statePictures/Location_Sachsen-Anhalt.svg";
import sachsen from "./statePictures/Location_Sachsen.svg";
import baden_wuertemberg from "./statePictures/Location_Baden-Wurttemberg.svg";
import thueringen from "./statePictures/Location_Thuringen.svg";
import schlesswig_holstein from "./statePictures/Location_Schleswig-Holstein.svg";

const pictureCodes =  {
  "DE":{
  "BW":baden_wuertemberg,
  "BY":bayern,
  "BE": berlin,
  "BB":brandenburg,
  "HB": bremen,
  "HH":hamburg,
  "HE": hessen,
  "MV":mecklenburg_vorpommern,
  "NI":niedersachsen,
  "NW":nordrhein_westfalen,
  "RP":rheinland_pfalz,
  "SL":saarland,
  "SN":sachsen,
  "ST":sachsen_anhalt,
  "SH":schlesswig_holstein,
  "TH":thueringen
}
}

class List extends Component {
  constructor(props) {
    super(props);
    this.state = {
      holidaysData: [],
      state: "",
      country:"DE"
    };
  }

  renderLinksJSX() {
    let linkJSX = [];
    let codeNames = codeNamesJSON.codes;

    for (let code in codeNames[this.state.country]) {
      let it = code;
      code = codeNames[this.state.country][code];
      var image = "";
      if(pictureCodes[this.state.country] && code[0]) image = pictureCodes[this.state.country][code[0]];

      linkJSX.push(
        <Link
          key={`Link/${this.state.country}/${code[0]}`+it}
          to={`?country=${this.state.country}&state=${code[0]}`}
          style={{
            float: "left",
            textDecoration: "none",
            display:(code[0].toUpperCase() === this.state.state)?"none":"inherit"
          }}
        >
          <div
            style={{
              display:"flex",
              flexDirection:"column",
              textAlign:"center",
              borderRadius:"5px",
              background:`linear-gradient(${code[2]}, ${code[3]})`,
              height: "130px",
              width: "130px",
              padding: "10px",
              margin:"10px",
              justifyContent:"center",
              alignItems:"center",
              color:"white",
              fontSize:"large",
              fontWeight:"bold"
            }}>
          <img src={image} style={{
            height:"85px",
            padding:"2px"
          }} alt={code[1]}/>
            {code[1]}
          </div>
        </Link>
      );
    }
    return linkJSX;
  }

  start() {
      if(this.state.state !== this.props.state) {
        console.log(this.props.country);
        this.setState({
        country: this.props.country,
        state: this.props.state,
      });
    }
  }

  componentDidMount() {
    this.start();
  }

  componentDidUpdate() {
    this.start();
  }

  render() {
    return (
      <div>
        <div
          style={{
            textAlign:"center",
            fontFamily: "'Titillium Web', sans-serif",
            display: "flex",
            flexDirection: "column",
            alignItems: "center"
          }}
        >
          <h1>Wähle dein Bundesland:</h1>
          <div style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            flexFlow:"wrap",
            margin:"10px"
          }}>
          {this.renderLinksJSX()}
          </div>
        </div>
      </div>
    );
  }
}

export default List;
