import json from "./holidays.json";
export function msToDays(ms) {
  return ms / (1000 * 60 * 60 * 24);
}

export function includeWeekends(date, direction) {
  var newDate = new Date(date);
  console.log(newDate.getDay(), newDate, direction);
  while((newDate.getDay() <= 1 && direction < 0) ||  (newDate.getDay() >= 5 && direction > 0)) {
    console.log("SetDate...");
    newDate.setDate(newDate.getDate() + direction);
  }
  console.log(newDate.getDay(), newDate, direction);
  // // var newDate = new Date(date.toDateString());
  // console.log("New:",newDate);
  // if ((newDate.getDay() > 6 && direction > 0) || newDate.getDay() === 0 || (newDate.getDay() < 2 && direction < 0)) {
  //   newDate.setDate(newDate.getDate() + direction);
  //   newDate = includeWeekends(newDate, direction);
  // }
  //   console.log(newDate);
  return newDate;
}

export function getHolidays(stateCode, country) {
  return new Promise(function (resolve, reject) {
    let currentHolidays = [];
    if (typeof country !== "string") country = "de";

    country = country.toUpperCase();

    try {
      for (let holiday in json) {
        holiday = json[holiday];
        if (holiday && holiday.country === country) {
          holiday.enddate = includeWeekends(holiday.enddate, 1);
          holiday.startdate = includeWeekends(holiday.startdate, -1);
          let endDate = holiday.enddate;

          if (endDate > new Date()) {
            console.log("curr:",holiday);
            if (!stateCode || holiday.state === stateCode) {
              var holidayName = holiday.name;
              var holidayLength = msToDays(
                new Date(holiday.enddate) - new Date(holiday.startdate)
              );
              var from = holiday.startdate;
              var to = holiday.enddate;
              var daysTill = Math.floor(
                msToDays(
                  new Date(holiday.startdate) - new Date().setHours(0, 0, 0, 0)
                )
              );
              if (daysTill < 0) {
                holidayLength += daysTill;
                daysTill = 0;
              }
              currentHolidays.push([
                holidayName,
                holidayLength,
                daysTill,
                from,
                to,
              ]);
            }
          }
        }
      }
      currentHolidays.sort((a, b) => a[2] - b[2]);
      if (currentHolidays.length > 0) {
        resolve(currentHolidays);
      } else {
        resolve(false);
      }
    } catch (e) {
      console.error(e);
      resolve(false);
    }
  });
}
