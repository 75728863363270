import React, { Component } from "react";
import DaysTill from "./components/daysTill";
import * as logic from "./logic/loadData.js";
import List from "./list.jsx";

import spring from "./components/pictures/spring.png";
import summer from "./components/pictures/summer.png";
import autumn from "./components/pictures/autumn.png";
import winter from "./components/pictures/winter.png";
import fallback from "./components/pictures/fallback.png";

var year = new Date().getFullYear();
const fromToArray = [
  [winter, new Date("03/20/" + year) - new Date().setFullYear(year, 0, 0)],
  [spring, new Date("06/21/" + year) - new Date().setFullYear(year, 0, 0)],
  [summer, new Date("09/22/" + year) - new Date().setFullYear(year, 0, 0)],
  [autumn, new Date("12/21/" + year) - new Date().setFullYear(year, 0, 0)],
  [
    winter,
    new Date().setFullYear(year + 1, 0, 0) - new Date().setFullYear(year, 0, 0),
  ],
];

class Overview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      holidaysData: [],
      state: "",
      country: "DE",
    };
    this.countDownCardRenderer = this.countDownCardRenderer.bind(this);
  }

  countDownCardRenderer() {
    let countDownCardRendererJSX = [];
    let startYearDate = new Date().setFullYear(
      new Date().getUTCFullYear(),
      0,
      0
    );

    for (let holidayData in this.state.holidaysData) {
      var iteration = holidayData;
      holidayData = this.state.holidaysData[holidayData];
      let hue = logic.msToDays(new Date(holidayData[3]) - startYearDate);
      hue = hue % 360;
      var currentPic = fallback;
      var found = false;
      for (let item in fromToArray) {
        item = fromToArray[item];
        if (logic.msToDays(new Date(item[1])) > hue && !found) {
          found = true;
          currentPic = item[0];
        }
      }
      countDownCardRendererJSX.push(
        <DaysTill
          key={iteration + "daysTill"}
          name={holidayData[0]}
          image={currentPic}
          length={holidayData[1]}
          days={holidayData[2]}
          from={holidayData[3]}
          to={holidayData[4]}
          color={`hsl(${hue}, 38%, 47%)`}
          badge={holidayData[2] > 0 ? "#6a5aff" : "#FF5F5F"}
        />
      );

      if (iteration === 0)
        countDownCardRendererJSX.push(
          <h3
            style={{
              marginLeft: "20px",
            }}
          >
            Weitere Ferien:{" "}
          </h3>
        );
    }
    return <div key="countDownCards">{countDownCardRendererJSX}</div>;
  }

  start() {
    let state;
    let country = "DE";
    let params = {};

    if (this.props.match && this.props.match.params)
      params = this.props.match.params;
    if (this.props.location.search) {
      let search = this.props.location.search;
      search = search.substring(1, search.length);
      search = search.split("&");

      search = search.map((item) => item.split("="));

      search.forEach((param) => {
        params[param[0]] = param[1];
      });
    }

    if (params["state"]) state = params.state.toUpperCase();

    if (params["country"]) country = params.country;

    if (state !== this.state.state) {
    logic.getHolidays(params.state, params.country).then((holidayData)=>{
      if(holidayData) {

      console.log(holidayData, params.state, params.country);
      this.setState({
        holidaysData: holidayData,
        country: country,
        state: state,
      });
    }
    });
  }
  }

  componentDidMount() {
    this.start();
  }

  componentDidUpdate() {
    this.start();
  }

  render() {
    if(!this.state.state) {
      return (<div><List state={this.state.state} country={this.state.country} /></div>);
    }
    return (
      <div>
        <h3
          style={{
            marginLeft: "20px",
            fontFamily: "'Titillium Web', sans-serif",
          }}
        >
          Nächste Ferien:
        </h3>
        {this.countDownCardRenderer()}
      </div>
    );
  }
}

export default Overview;
