import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import Overview from "./pages/overview.jsx";

function App() {
  return (
    <div>
      <Router>
        <Switch>
        <Route exact path="/:country/:state" component={Overview}/>
        <Route exact path="/" component={Overview}/>
        <Route exact path="/:country/" component={Overview}/>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
