import React, { Component } from "react";

class DaysTill extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.getCounterMessage = this.getCounterMessage.bind(this);
  }

  getCounterMessage() {
    if (this.props.days && this.props.days > 0) {
      return `in ${this.props.days} Tage`;
    } else {
      let holidayLength = this.props.length;
      if (window.isNaN(holidayLength)) holidayLength = 0;
      return `noch ${Math.floor(holidayLength)} Tage`;
    }
  }

  firstLetterUpperCase(name) {
    return name[0].toUpperCase()+name.substring(1,name.length)
  }
  render() {
    return (
      <div>
        <div
          style={{
            textAlign: "center",
            backgroundColor: this.props.color,
            color: "white",
            margin: "20px",
            borderRadius: "20px",
            fontFamily: "'Titillium Web', sans-serif"
          }}
        >
          <div
            style={{
              maxHeight: "min(50vh, 300px)",
              overflow: "hidden",
              position: "relative",
            }}
          >
            <img
              src={this.props.image}
              alt=""
              style={{
                maxWidth: "100%",
                borderRadius: "20px 20px 0px 0px",
              }}
            />
            <div
              style={{
                position: "absolute",
                right: "5px",
                top: "5px",
                backgroundColor: this.props.badge,
                padding: "4px",
                borderRadius: "6px",
                margin:"5px",
                fontWeight: "600"
              }}
            >
              {this.getCounterMessage()}
            </div>
          </div>

          <h2
            style={{
              marginBottom: 0,
              marginTop: 0,
              clear: "both",
              width: "100%"
            }}
          >
            {this.firstLetterUpperCase(this.props.name)}
          </h2>
          <h3 style={{
            paddingBottom:"15px",
            marginTop: 0
          }}>
            {(new Date(this.props.from)).toLocaleDateString('de-DE')} - {(new Date(this.props.to)).toLocaleDateString('de-DE')}
          </h3>
        </div>
      </div>
    );
  }
}

export default DaysTill;
